import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

import Layout from "../components/Layout";


export const JobPageTemplate = ({ date, category, location, tags, title, description, contact }) => {
  
  return (
    <section className="section">
      <div className="content-wrapper small flex-row justc-space-b alitems-start
                                            m-flex-column">
        <div className="flex-column justc-start job-metadata">
          <div className="full-width">
            <Link to="/carrieres/"
                  className="cta small fill blue with-icon-left mgb-s mgt-xs">
            <img src="/img/icons/arrow-left-white.svg" alt="arrow left" />
            Retour à la liste
            </Link>
          </div>
          <h5 className="title-5 light-medium mgb-xs">
            {date}
          </h5>
          <div className="job-subtitle flex-row justc-start alitems-start mgb-s">
            <div className="job-category mgr-m">
              <img className="job-icon" src="/img/icons/tag-icon.svg" alt='tag-icon' />
              {category}
            </div>
            <div className="job-location">
              <img className="job-icon" src="/img/icons/location-icon.svg" alt='tag-icon' />
              {location}
            </div>
          </div>
          <div className="job-tags mgb-m">
            {tags && (tags.map((tag, index) => (
              <span key={index} className="job-tag">
                {tag}
              </span>)))}
          </div>
        </div>
        <div className="flex-column job-offer-content">
          <h1 className="title-2 bold-weight text-medium mgb-m">
            {title}
          </h1>
          <div>
            <ReactMarkdown className="job-description markdown-text" children={description}/>
          </div>
        </div>
      </div>
      <div className="flex-row justc-center mgt-l">
        <a href={`mailto:${contact}`} className="cta fill yellow">Nous contacter à l'adresse {contact}</a>
      </div>
    </section>
  )
}

JobPageTemplate.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}


const JobOffer = ({ data }) => {
  const { markdownRemark: job, footerData, navbarData } = data

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet titleTemplate="%s | Jobs">
        <title>{`${job.frontmatter.title}`}</title>
      </Helmet>
      <JobPageTemplate date={job.frontmatter.date}
                       category={job.frontmatter.category}
                       location={job.frontmatter.location}
                       tags={job.frontmatter.tags}
                       title={job.frontmatter.title}
                       description={job.frontmatter.description}
                       contact={job.frontmatter.contact}/>
    </Layout>
  )
}

JobOffer.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default JobOffer

export const pageQuery = graphql`
  query JobOfferByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        location
        category
        tags
        date
        contact
        description
      }
    }
    ...LayoutFragment
  }
`